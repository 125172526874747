// Auth TokenFactory knows about the tokens we expect from server & nothing else
function TokenFactory(store, jwtHelper, $log) {
  function Token(name) {
    if (!(this instanceof Token)) { return new Token(name); }
    this.name = name;
    // undefined indicates no token
    // 0 indicates an expired token
    Object.defineProperty(this, 'token', {
      get: function tokenGetFn() {
        return store.get(this.name);
      },
      set: function tokenSetFn(request) {
        _setToken(this.name, request);
      }
    });
  }

  Token.prototype.decode = function decode() {
    return jwtHelper.decodeToken(this.token);
  };

  // TODO: maybe make it so that we always check isExpired in the getter of
  // token
  Token.prototype.isExpired = function isExpired() {
    return jwtHelper.isTokenExpired(this.token);
  };

  Token.prototype.clear = function clear() {
    return store.remove(this.name);
  };

  /*
  * @private
  */
  function _setToken(name, request) {
    if (request) {
      try {
        store.set(name, request[name]);
      } catch(e) {
        $log.error('TokenFactory expects object with a token name proprty', e);
      }
    } else { // Token.userToken = ''; a deletion event
      $log.error('must provide data to set token');
    }
  }
  return Token;
}

angular.module('sq.services.token', ['angular-jwt', 'ngCookies', 'angular-storage'])
.factory('Token', TokenFactory);
